import { useEffect, useState } from "react";

import Formatter from "./Formatter";

export const useDebug = () => {
  const [debug, _debug] = useState<boolean>(false);

  useEffect(() => {
    _debug(Boolean(Formatter.gup("debug")));
  }, []);

  return debug;
};

export default useDebug;
