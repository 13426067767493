import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { Tooltip } from "@with-nx/editor-ui";
import { Currency, useCustomer } from "@with-nx/hooks-n-helpers";
import { useServerRegion } from "@with-nx/region";
import {
  DesignedButton,
  DesignedCheck,
  DesignedIcon,
  DesignedInput,
  DesignedSelect,
} from "@with-nx/simple-ui/atoms";
import { useCallback, useState } from "react";
import Toast from "react-hot-toast";
import { Box } from "simple-effing-primitive-layout";

import Rule from "../../../../atoms/src/lib/rule/rule";
import Colors from "../../../../helpers/src/lib/Colors";

interface OrderSummaryProps {
  subtotal?: {
    license?: string;
    products?: string;
    shipping?: string;
    rushFee?: string;
    expeditedFee?: string;
  };
  discounts?: {
    license?: string;
    products?: string;
  };
  total?: string;
  discount?: string;
  onContinuePress?: () => void;
  onSendEmailInvoiceDetailsPress?: () => void;
  onExportPDFInvoiceDetailsPress?: () => void;
  onPayByCardPress?: () => void;
  onPayByRoyaltyOnlyPress?: () => void;
  onUploadPurchaseOrderPress?: () => void;

  step?: "productions" | "continue" | "pay";
  licensors?: string[];
  canPay?: boolean;
  canProgress?: boolean;
  royaltiesOnly?: boolean;
  loading?: boolean;
  promotions?: {
    code: string;
    amount?: string;
    percentage?: number;
    fixed?: boolean;
  }[];
  onRemoveDiscount?: (code: string) => void;
  reason?: string;
}

export const OrderSummary = ({
  subtotal,
  total,
  onContinuePress,
  onPayByCardPress,
  onPayByRoyaltyOnlyPress,
  onUploadPurchaseOrderPress,
  onExportPDFInvoiceDetailsPress,
  step,
  licensors,
  canPay = false,
  canProgress,
  royaltiesOnly = false,
  loading = false,
  discount,
  discounts,
  promotions,
  onRemoveDiscount,
  reason,
}: OrderSummaryProps) => {
  const [agree, _agree] = useState(false);
  const [printing, _printing] = useState(false);
  const customer = useCustomer({
    load: (c, o) => {
      if (o.needsUpdating) {
        openCustomerEditModal();
      }
    },
  });
  const [modal, _modal] = useState<{
    open: boolean;
    organization: string | null;
    businessType: number;
    callback?: () => void;
    loading?: boolean;
  }>({
    open: false,
    organization: null,
    businessType: 11,
    loading: false,
  });

  const subtotalLicensed = Number(subtotal?.license || "0");
  const discountLicensed = Number(discounts?.license || "0");
  const subtotalNonLicensed = Number(subtotal?.products || "0");
  const discountNonLicensed = Number(discounts?.products || "0");

  const subTotalShipping = Number(subtotal?.shipping || "0");
  const subTotalRushFee = Number(subtotal?.rushFee || "0");
  const subTotalExpeditedFee = Number(subtotal?.expeditedFee || "0");
  const totalDiscount = Number(discount || "0");
  const totalBillable = Number(total || "0");

  const region = useServerRegion();

  const openCustomerEditModal = useCallback(
    (callback?: () => unknown) => {
      _modal({
        open: true,
        organization: customer?.organization || null,
        businessType: customer?.businessType || 11,
        callback,
        loading: false,
      });
    },
    [customer]
  );

  return (
    <>
      <Box parse="br:10 pa:20" color={Colors.foreground}>
        <Modal
          isOpen={modal.open}
          blockScrollOnMount={false}
          onClose={() => {
            _modal((m) => ({ ...m, open: false }));
          }}
          size={{ base: "sm", md: "lg" }}
        >
          <ModalOverlay />
          <ModalContent borderRadius="md" backgroundColor="var(--foreground)">
            <>
              <ModalHeader color="var(--font1)">
                Update Account Details
              </ModalHeader>
              <ModalBody>
                <Rule
                  rule="ls"
                  display="block"
                  bottom={24}
                  color="var(--font4)"
                >
                  Some of your account details are required in order to complete
                  your order process.
                </Rule>
                <DesignedInput
                  label="Organization Name"
                  value={modal?.organization || ""}
                  change={(organization) =>
                    _modal((m) => ({ ...m, organization }))
                  }
                />
                <DesignedSelect
                  label="Business Type"
                  value={modal?.businessType ? String(modal?.businessType) : ""}
                  change={(businessType) =>
                    _modal((m) => ({
                      ...m,
                      businessType: Number(businessType),
                    }))
                  }
                  options={customer?.types?.map((item) => {
                    return [String(item.businessType), item.name];
                  })}
                />
                <Box parse="d:flex a:center">
                  <DesignedButton
                    label="Update Details"
                    theme="primary"
                    loading={modal.loading}
                    press={async () => {
                      if (typeof modal.businessType !== "number") {
                        Toast.error("Please select a business type.");
                        return;
                      }

                      if (Number(modal.organization?.length) <= 4) {
                        Toast.error("Please enter a valid organization name.");
                        return;
                      }

                      if (modal.loading) {
                        return;
                      }
                      _modal((m) => ({ ...m, loading: true }));

                      await customer.methods.patch({
                        organization: modal.organization || "",
                        business_type: modal.businessType,
                      });

                      _modal((m) => ({ ...m, loading: false, open: false }));
                    }}
                    properties={{
                      right: 12,
                    }}
                  />
                </Box>
              </ModalBody>
            </>

            <ModalFooter />
          </ModalContent>
        </Modal>
        <Rule parse="!ht d:block mb:12">Order Summary</Rule>
        {subtotalLicensed ? (
          <Box parse="mb:12">
            <Box parse="d:flex a:center mb:2 j:space-between">
              <Rule parse="!ls">Products Subtotal: </Rule>
              <Box parse="d:inline-flex fd:column a:flex-end">
                <Rule parse="!ls d:block" color={Colors.font2}>
                  {Currency.format(
                    discountLicensed + subtotalLicensed,
                    region?.currency?.code || "USD"
                  )}
                </Rule>
              </Box>
            </Box>
            <Box parse="d:flex a:center">
              <Rule parse="!ls" color={Colors.warning} right={4}>
                {licensors
                  ? `Will be billed later (${licensors.join(", ")})`
                  : "Will be billed later"}
              </Rule>
            </Box>
          </Box>
        ) : undefined}
        {subtotalNonLicensed ? (
          <>
            <Box parse="d:flex a:center mb:16 j:space-between">
              <Rule parse="!ls">Subtotal: </Rule>

              <Box parse="d:inline-flex fd:column a:flex-end">
                <Rule parse="!ls d:block" color={Colors.font2}>
                  {Currency.format(
                    discountNonLicensed + subtotalNonLicensed,
                    region?.currency?.code || "USD"
                  )}
                </Rule>
              </Box>
            </Box>

            {subTotalShipping ? (
              <Box parse="d:flex a:center mb:16 j:space-between">
                <Rule parse="!ls">Shipping: </Rule>
                <Rule parse="!ls d:block" color={Colors.font2}>
                  {Currency.format(
                    subTotalShipping,
                    region?.currency?.code || "USD"
                  )}
                </Rule>
              </Box>
            ) : undefined}

            {subTotalRushFee ? (
              <Box parse="d:flex a:center mb:16 j:space-between">
                <Rule parse="!ls">Rush Fee: </Rule>
                <Rule parse="!ls d:block" color={Colors.font2}>
                  {Currency.format(
                    subTotalRushFee,
                    region?.currency?.code || "USD"
                  )}
                </Rule>
              </Box>
            ) : undefined}

            {subTotalExpeditedFee ? (
              <Box parse="d:flex a:center mb:16 j:space-between">
                <Rule parse="!ls">Expedited Shipment Fee: </Rule>
                <Rule parse="!ls d:block" color={Colors.font2}>
                  {Currency.format(
                    subTotalExpeditedFee,
                    region?.currency?.code || "USD"
                  )}
                </Rule>
              </Box>
            ) : undefined}
          </>
        ) : undefined}

        {totalDiscount ? (
          <>
            <Box parse="mb:16 h:1 w:100%" color={Colors.accent} />
            {promotions?.map((promotion) => (
              <Box
                parse="d:flex a:center mb:16 j:space-between"
                key={promotion.code}
              >
                <Box parse="d:inline-flex a:center">
                  <Rule parse="!ls c:?font3 mr:5">{promotion.code}</Rule>
                  {!promotion.fixed ? (
                    <Tooltip
                      position="top-left"
                      label="Remove Promotional Discount"
                    >
                      <DesignedIcon
                        name="multiply/bold"
                        size={14}
                        color="var(--entity)"
                        press={() => onRemoveDiscount?.(promotion.code)}
                      />
                    </Tooltip>
                  ) : undefined}
                </Box>
                <Rule parse="!ls c:?font3" color={Colors.font1}>
                  {promotion.percentage
                    ? `${(promotion.percentage * 100).toFixed(0)}%`
                    : undefined}
                  {promotion.amount
                    ? Currency.format(
                        Number(promotion.amount),
                        region?.currency?.code || "USD"
                      )
                    : undefined}
                </Rule>
              </Box>
            ))}
            <Box parse="d:flex a:center mb:16 j:space-between">
              <Rule parse="!lm">Discounts: </Rule>
              <Rule parse="!lm" color={Colors.font1}>
                {Currency.format(
                  totalDiscount,
                  region?.currency?.code || "USD"
                )}
              </Rule>
            </Box>
          </>
        ) : undefined}
        <Box parse="mb:16 h:1 w:100%" color={Colors.accent} />
        <Box parse="d:flex a:center mb:16 j:space-between">
          <Rule parse="!lm">Total Due: </Rule>
          <Rule parse="!lm" color={Colors.font1}>
            {Currency.format(totalBillable, region?.currency?.code || "USD")}
          </Rule>
        </Box>
        {step === "pay" ? (
          <>
            {royaltiesOnly ? (
              <DesignedButton
                loading={loading}
                disable={
                  !agree || !canPay
                    ? !agree
                      ? ["Please agree to the terms and conditions."]
                      : reason
                      ? [reason]
                      : true
                    : false
                }
                label="Complete Purchase"
                theme="primary"
                press={
                  customer?.options?.needsUpdating
                    ? () => openCustomerEditModal(onPayByRoyaltyOnlyPress)
                    : onPayByRoyaltyOnlyPress
                }
                properties={{
                  width: "100%",
                  display: "flex",
                  align: "center",
                  justify: "center",
                  bottom: 12,
                  style: { justifyContent: "center", width: "100%" },
                  native: { cypress: "button-checkout" },
                }}
                container={{ style: { width: "100%", display: "block" } }}
                icon="check/bold"
              />
            ) : (
              <DesignedButton
                disable={
                  !agree || !canPay
                    ? !agree
                      ? ["Please agree to the terms and conditions."]
                      : reason
                      ? [reason]
                      : true
                    : false
                }
                label="Pay by Card"
                theme="primary"
                press={
                  customer?.options?.needsUpdating
                    ? () => openCustomerEditModal(onPayByCardPress)
                    : onPayByCardPress
                }
                properties={{
                  width: "100%",
                  display: "flex",
                  align: "center",
                  justify: "center",
                  bottom: 12,
                  style: { justifyContent: "center", width: "100%" },
                  native: { cypress: "button-checkout" },
                }}
                container={{ style: { width: "100%", display: "block" } }}
                icon="card/bold"
              />
            )}
            <Box parse="d:flex a:flex-start">
              <DesignedCheck
                properties={{
                  parse: "d:flex a:flex-start",
                  native: {
                    cypress: "checkbox-agree",
                  },
                }}
                active={agree}
                press={() => _agree(!agree)}
              />
              <Box parse="f:1 ml:10" press={() => _agree(!agree)}>
                <Rule parse="!pm c:?font1">
                  I have read and agreed to the{" "}
                  <a
                    style={{
                      textDecoration: "underline",
                    }}
                    target="_blank"
                    href="/legal/privacy-policy"
                  >
                    privacy policy
                  </a>{" "}
                  and{" "}
                  <a
                    style={{
                      textDecoration: "underline",
                    }}
                    target="_blank"
                    href="/legal/terms-and-conditions"
                  >
                    terms of service
                  </a>
                </Rule>
              </Box>
            </Box>

            {customer.options.canUploadPurchaseOrder && !royaltiesOnly ? (
              <>
                <Box parse="d:flex a:center mt:12 mb:12">
                  <Box parse="f:1 h:1 c:?accent" />
                  <Box parse="d:inline-flex">
                    <Rule parse="!lt c:?font4 mr:10 ml:10">OR</Rule>
                  </Box>
                  <Box parse="f:1 h:1 c:?accent" />
                </Box>
                <DesignedButton
                  disable={
                    !agree || !canPay
                      ? !agree
                        ? ["Please agree to the terms and conditions."]
                        : reason
                        ? [reason]
                        : true
                      : false
                  }
                  label="Upload Purchase Order"
                  theme="tetriary"
                  press={
                    customer?.options?.needsUpdating
                      ? () => openCustomerEditModal(onUploadPurchaseOrderPress)
                      : onUploadPurchaseOrderPress
                  }
                  properties={{
                    width: "100%",
                    display: "flex",
                    align: "center",
                    justify: "center",
                    bottom: 12,
                    style: { justifyContent: "center", width: "100%" },
                    native: { cypress: "button-upload-purchase-order" },
                  }}
                  container={{ style: { width: "100%", display: "block" } }}
                />
              </>
            ) : undefined}
          </>
        ) : step !== "productions" ? (
          <>
            <DesignedButton
              label="Continue"
              theme="primary"
              press={onContinuePress}
              disable={!canProgress}
              properties={{
                width: "100%",
                display: "block",
                align: "center",
                justify: "center",
                bottom: 12,
                style: { justifyContent: "center", width: "100%" },
                native: { cypress: "button-checkout" },
              }}
              container={{ style: { width: "100%", display: "block" } }}
            />
            <DesignedButton
              loading={printing}
              press={() => {
                if (printing) {
                  return;
                }

                _printing(true);

                onExportPDFInvoiceDetailsPress?.();

                setTimeout(() => {
                  _printing(false);
                }, 5000);
              }}
              label="Export Invoice Details"
              theme="tetriary"
              properties={{
                width: "100%",
                display: "flex",
                align: "center",
                justify: "center",
                style: { justifyContent: "center", width: "100%" },
                native: { cypress: "button-export-invoice-details" },
              }}
              container={{ style: { width: "100%", display: "block" } }}
            />
          </>
        ) : undefined}
      </Box>

      <Box parse="br:10 mt:24 pa:20" color={Colors.foreground}>
        <Rule parse="!ht mb:12" display="block">
          Welcome to our new website!
        </Rule>

        <Rule display="block" parse="!lm mb:12" weight="300">
          If you encounter any technical issues, please reach out to us at 1
          (800) 277-0343.
        </Rule>
        <Rule parse="!lm mb:24" weight="300">
          To streamline the ordering process, proceed with checkout on our
          website. Alternatively, you can download our&nbsp;
          <Rule parse="!lm" weight="600">
            <a
              rel="nofollow"
              style={{
                color: "var(--white)",
                textDecoration: "underline",
              }}
              target="_blank"
              href="/legal/documents/order-form"
            >
              PDF order form
            </a>
          </Rule>
          &nbsp;and submit it to accounts@broadwaymedia.com for processing.
        </Rule>
      </Box>
    </>
  );
};

export default OrderSummary;
