/* eslint-disable @typescript-eslint/no-explicit-any */
import { ThrottlerController, useService } from "@with-nx/hooks-n-helpers";
import { useServerRegion } from "@with-nx/region";
import { useEffect, useState } from "react";

import { Ecommerce } from "../types/types";

export interface UseProductsResponse {
  page: number;
  pageSize: number;
  total: number;
  result: Ecommerce.Schemas.SearchProductSchemaType[];
}

interface UseProductsOptions {
  currentPage?: number;
  pageSize?: number;
  searchText?: string;
  filterQuery?: string;
  variation?: string;
  selectedVersions?: string[];
  productCategory?: string;
  selectedResources?: string[];
  selectedLicensors?: string[];
  selectedShowSlugs?: string[];
  selectedShowBrandSlugs?: string[];
  selectedShowIds?: string[];
  selectedShowBrandIds?: string[];
  throttler?: boolean;
}

export function useProducts(options: UseProductsOptions = {}, ready?: boolean) {
  const {
    currentPage = 1,
    pageSize = 12,
    searchText = "",
    filterQuery = "",
    selectedVersions = [],
    productCategory,
    selectedResources = [],
    selectedLicensors = [],
    selectedShowSlugs = [],
    selectedShowBrandSlugs = [],
    selectedShowIds = [],
    selectedShowBrandIds = [],
  } = options;

  const [data, _data] = useState<any>();
  const [loading, _loading] = useState<boolean>(false);
  const [error] = useState<string | undefined>(undefined);
  const service = useService("microservice", {
    cache: 600_000,
  });

  const region = useServerRegion();

  const params = new Map();
  params.set("pagination[page]", currentPage.toString());
  params.set("pagination[pageSize]", pageSize.toString());
  params.set("includes[description]", "true");
  params.set("includes[attachments]", "true");

  if (searchText && searchText.length > 0) {
    params.set("filter[searchText]", searchText);
  }
  if (filterQuery && filterQuery.length > 0) {
    params.set(filterQuery, "");
  }
  if (selectedVersions.length > 0) {
    selectedVersions.forEach((version, index) => {
      params.set(`filter[versions][${index}]`, version.toUpperCase());
    });
  }
  if (selectedLicensors.length > 0) {
    selectedLicensors.forEach((licensor, index) => {
      params.set(`filter[licensorsIds][${index}]`, licensor.toString());
    });
  }
  if (selectedResources.includes("choreoGuide")) {
    params.set("filter[resources][0]", "CHOREO_GUIDE");
  }
  if (selectedResources.includes("scenicProjections")) {
    params.set("filter[resources][1]", "SCENIC_PROJECTION_PACKAGE");
  }
  if (selectedShowSlugs.length > 0) {
    selectedShowSlugs.forEach((slug, index) => {
      params.set(`filter[show_slugs][${index}]`, slug);
    });
  }
  if (selectedShowBrandSlugs.length > 0) {
    selectedShowBrandSlugs.forEach((slug, index) => {
      params.set(`filter[showBrand_slugs][${index}]`, slug);
    });
  }
  if (selectedShowIds.length > 0) {
    selectedShowIds.forEach((id, index) => {
      params.set(`filter[show_ids][${index}]`, id);
    });
  }
  if (selectedShowBrandIds.length > 0) {
    selectedShowBrandIds.forEach((id, index) => {
      params.set(`filter[showBrand_ids][${index}]`, id);
    });
  }

  const search = async (
    origin?: string,
    options?: {
      selectedShowSlugs?: string[];
      selectedShowBrandSlugs?: string[];
      selectedShowIds?: string[];
      selectedShowBrandIds?: string[];
    }
  ) => {
    console.log("🔎", origin, selectedShowIds, selectedShowBrandIds, options);

    if (options?.selectedShowSlugs?.length) {
      options?.selectedShowSlugs?.forEach((slug, index) => {
        params.set(`filter[show_slugs][${index}]`, slug);
      });
    }
    if (options?.selectedShowBrandSlugs?.length) {
      options?.selectedShowBrandSlugs?.forEach((slug, index) => {
        params.set(`filter[showBrand_slugs][${index}]`, slug);
      });
    }
    if (options?.selectedShowIds?.length) {
      options?.selectedShowIds?.forEach((id, index) => {
        params.set(`filter[show_ids][${index}]`, id);
      });
    }
    if (options?.selectedShowBrandIds?.length) {
      options?.selectedShowBrandIds?.forEach((id, index) => {
        params.set(`filter[showBrand_ids][${index}]`, id);
      });
    }

    try {
      _loading(true);
      const parameters = (() => {
        const t = {};
        params.forEach((value, key) => {
          t[key] = value;
        });

        return t;
      })();

      const data = await service(
        "GET",
        `/ecommerce/products/search/${region?.id || 1}/${productCategory}`,
        parameters
      );

      _data(data as UseProductsResponse);
      _loading(false);
      return true;
    } catch (error) {
      console.error(error);
      _loading(false);
      return false;
    }
  };

  useEffect(() => {
    if (
      !ThrottlerController.can("products", "reload", 10000) &&
      options.throttler
    ) {
      return;
    }

    if (ready === false) {
      return;
    }

    search();
  }, [
    currentPage,
    pageSize,
    searchText,
    filterQuery,
    selectedVersions,
    productCategory,
    selectedResources,
    selectedLicensors,
    ready,
  ]);

  return {
    products: data ? data : null,
    isLoading: loading,
    isError: error,
    search,
  };
}
